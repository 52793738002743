import http from '../../../../../../libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_INFORME_EJECUTIVO
const createInforme = (data) => http.post(`${baseUrl}/executive_report/create-informe-ejecutivo`, data, { headers: { loading: true } })
const getClientesList = () => http.get(`${baseUrl}/executive_report/get-clientes-list_sap`, { headers: { loading: true } })
const getListInformes = (query) => http.get(`${baseUrl}/executive_report/get-list-informe`, { params: { ...query } })
const removeInforme = (id) => http.delete(`${baseUrl}/executive_report/${id}`, { headers: { loading: true } })
const update = (id) => http.put(`${baseUrl}/executive_report/${id}`, {}, { headers: { loading: true } })
export {
  createInforme,
  getClientesList,
  getListInformes,
  removeInforme,
  update
}
