import axios from 'axios'
export default class PdfService {
  ver (params) {
    const url = `${process.env.VUE_APP_MICROSERVICE_API_INFORME_EJECUTIVO}/executive_report/verPdf`
    const res = axios.getUri({
      url,
      params
    })
    window.open(res, '_blank')
  }
}
