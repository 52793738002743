<template>
  <Card>
    <template #content>
      <div>
        <div class="grid grid-cols-10 gap-10">
          <div class="flex flex-col col-span-2">
            <span class="font-bold mb-3">Cliente:</span>
            <Dropdown
              v-model="modelo.cliente"
              :options="clientesList"
              optionLabel="name"
              optionValue="code"
              dataKey="code"
              placeholder="Seleccione un cliente"
              filter
              filterPlaceholder="Buscar por nombre de cliente"
            >
              <template #option="{option}">
                <span>{{ option.code }} - {{ option.name }}</span>
              </template>
            </Dropdown>
            <span class="text-red-700" v-if="errors.cliente">{{ errors.cliente }}</span>
          </div>
          <div class="flex flex-col col-span-2">
            <span class="font-bold mb-3">Año:</span>
            <Dropdown
              v-model="modelo.anio"
              :options="aniosList"
              optionLabel="year"
              optionValue="year"
              placeholder="Seleccione un año"
            />
          </div>
          <div class="flex flex-col col-span-2">
            <span class="font-bold mb-3">Mes Inicial:</span>
            <Dropdown
              v-model="modelo.mesInicial"
              :options="mesesList"
              optionLabel="name"
              optionValue="code"
              placeholder="Seleccione un mes"/>
            <span class="text-red-700" v-if="errors.mesInicial">{{ errors.mesInicial }}</span>
          </div>
          <div class="flex flex-col col-span-2">
            <span class="font-bold mb-3">Mes final:</span>
            <Dropdown
              v-model="modelo.mesFinal"
              :options="mesesList"
              optionLabel="name"
              optionValue="code"
              placeholder="Seleccione un mes"/>
            <span class="text-red-700" v-if="errors.mesFinal">{{ errors.mesFinal }}</span>
          </div>
          <div class="col-span-2 flex flex-col">
            <span class="font-bold mb-3">Tipo:</span>
            <div class="field-radiobutton">
              <RadioButton
                id="evento"
                name="tipo"
                value="Evento"
                v-model="modelo.tipo"
                @change="adjuntos = []"
              />
              <label for="evento" class="mr-3">Evento</label>
              <RadioButton
                id="capita"
                name="tipo"
                value="Capita"
                v-model="modelo.tipo"
                @change="adjuntos = []"
              />
              <label for="capita">Capita</label>
            </div>
          </div>
        </div>
        <div class="flex flex-col mt-10">
          <span class="font-bold mb-3">Introducción: </span>
          <quill-editor
            v-model:value="modelo.introduccion"
            :options="editorOptions"
          />
          <span class="text-red-700" v-if="errors.introduccion">{{ errors.introduccion }}</span>
        </div>
        <div class="flex flex-col mt-10">
          <span class="font-bold mb-3">Flujo de Recursos: </span>
          <quill-editor
            v-model:value="modelo.flujoRecursos"
            :options="editorOptions"
          />
          <span class="text-red-700" v-if="errors.flujoRecursos">{{ errors.flujoRecursos }}</span>
        </div>
        <div v-if="modelo.tipo === 'Evento'" class="w-100 text-sm ">
          <span class="my-4 font-medium">Como desea presentar la tabla y grafica de flujo de recursos</span>
          <div class="items-center flex mt-3">
            <RadioButton
              id="automatico"
              name="tipoGraficasFlujo"
              value="Automatico"
              v-model="tipoGraficasFlujo"
              class="mr-2"
            />
            <label class="mr-2" for="automatico">Automático</label>
            <RadioButton
              id="manual"
              name="tipoGraficasFlujo"
              value="Manual"
              v-model="tipoGraficasFlujo"
              class="text-sm mr-2"
            />
            <label for="manual" class="mr-3">Manual</label>
            <div v-if="tipoGraficasFlujo === 'Manual'" class="block">
              <div class="flex items-center">
                <div>
                  <div v-if="!adjuntos.find(a => a.fileName === 'Tabla')">
                    <Button @click="openMaximizable(1)" class="p-1 mr-2" label="Subir tabla" />
                  </div>
                  <div class="p-2 text-blue-600 flex items-center" v-if="adjuntos.find(a => a.fileName === 'Tabla')">
                    Tabla.png
                    <button @click="removeImage(1)" class="rounded-full bg-red-600 w-4 h-4 flex items-center justify-center mx-2">
                      <i style="font-size: 0.5rem;" class="pi pi-times text-white"></i>
                    </button>
                  </div>
                </div>
                <div>
                  <div v-if="!adjuntos.find(a => a.fileName === 'Grafica')">
                    <Button @click="openMaximizable(2)" class="p-1 mr-2" label="Grafica" />
                  </div>
                  <div class="p-2 text-blue-600 flex items-center" v-if="adjuntos.find(a => a.fileName === 'Grafica')">
                    Grafica.png
                    <button @click="removeImage(2)" class="rounded-full bg-red-600 w-4 h-4 flex items-center justify-center mx-2">
                      <i style="font-size: 0.5rem;" class="pi pi-times text-white"></i>
                    </button>
                  </div>
                </div>
              </div>
              <p class="text-red-600">
                {{ errFile }}
              </p>
            </div>
          </div>
          <div class="flex flex-col">
        </div>
        </div>
        <div v-if="modelo.tipo === 'Capita'" class="mt-10">
          <span class="font-bold">Tabla detallada de flujo de recursos</span>
          <div class="mt-5 mb-3">
            <div v-if="!adjuntos.find(a => a.fileName === 'Tabla')">
              <Button @click="openMaximizable(1)" class="p-1 mr-2" label="Subir tabla" />
            </div>
            <div class="p-2 text-blue-600 flex items-center" v-if="adjuntos.find(a => a.fileName === 'Tabla')">
              Tabla.png
              <button @click="removeImage(1)" class="rounded-full bg-red-600 w-4 h-4 flex items-center justify-center mx-2">
                <i style="font-size: 0.5rem;" class="pi pi-times text-white"></i>
              </button>
            </div>
          </div>
          <span class="text-red-600 ">
            {{ errFile }}
          </span>
        </div>
        <div class="flex flex-col mt-10" v-if="modelo.tipo === 'Capita'">
          <span class="font-bold mb-3">Caracterización y Gestión de Solicitudes: </span>
          <quill-editor
            v-model:value="modelo.gestionSolicitudes"
            :options="editorOptions"
          />
          <span class="text-red-700" v-if="errors.gestionSolicitudes">{{ errors.gestionSolicitudes }}</span>
        </div>
        <div class="flex flex-col mt-10" v-if="modelo.tipo === 'Evento'">
          <span class="font-bold mb-3">Gestión de Requerimientos y PQRs: </span>
          <quill-editor
            v-model:value="modelo.gestionRequerimientos"
            :options="editorOptions"
          />
          <span class="text-red-700" v-if="errors.gestionRequerimientos">{{ errors.gestionRequerimientos }}</span>
        </div>
        <div class="flex flex-col mt-10 ">
          <span class="font-bold mb-3">Moléculas: </span>
          <Chips v-model="modelo.moleculas" separator="," class="block"/>
          <span class="text-red-700" v-if="errors.moleculas">{{ errors.moleculas }}</span>
        </div>

        <div class="flex flex-col mt-10">
          <span class="font-bold mb-3">Conclusiones y Observaciones: </span>
          <quill-editor
            v-model:value="modelo.conclusiones"
            :options="editorOptions"
          />
          <span class="text-red-700" v-if="errors.conclusiones">{{ errors.conclusiones }}</span>
        </div>
        <div class="grid grid-cols-5 gap-10 mt-10" v-if="modelo.tipo === 'Capita'">
          <div class="flex flex-col">
            <span class="font-bold mb-3">Solicitudes en Plataforma: </span>
            <InputNumber :min="0" v-model="modelo.solicitudesPlataforma" />
            <span class="text-red-700" v-if="errors.solicitudesPlataforma">{{ errors.solicitudesPlataforma }}</span>
          </div>
          <div class="flex flex-col">
            <span class="font-bold mb-3">Requerimientos y PQRs: </span>
            <InputNumber :min="0" v-model="modelo.requerimientosPqrs" />
            <span class="text-red-700" v-if="errors.requerimientosPqrs">{{ errors.requerimientosPqrs }}</span>
          </div>
          <div class="flex flex-col">
            <span class="font-bold mb-3">Total Entregas: </span>
            <InputNumber :min="0" v-model="modelo.totalEntregas" />
            <span class="text-red-700" v-if="errors.totalEntregas">{{ errors.totalEntregas }}</span>
          </div>
          <div class="flex flex-col">
            <span class="font-bold mb-3">Entregas a Domicilio: </span>
            <InputNumber :min="0" v-model="modelo.entregasDomicilio" />
            <span class="text-red-700" v-if="errors.entregasDomicilio">{{ errors.entregasDomicilio }}</span>
          </div>
          <div class="flex flex-col">
            <span class="font-bold mb-3">Entregas en Oficinas: </span>
            <InputNumber :min="0" v-model="modelo.entregasOficinas" />
            <span class="text-red-700" v-if="errors.entregasOficinas">{{ errors.entregasOficinas }}</span>
          </div>
        </div>
        <div class="mt-10">
          <span><strong>NOTA:</strong> Para la correcta visualización del logo por favor adjuntar imagenes con fondo blanco y en formato .png</span>
          <div class="flex mt-5 items-center">
            <span class="font-bold mr-3">Logo: </span>
            <div v-if="logoI.length === 0">
              <Button @click="openMaximizable(3)"> Agregar + </Button>
            </div>
            <div class="flex items-center" v-else>
              <p class="text-blue-600 text-sm">
                {{ logoI[0].fileName }}.png
              </p>
              <button @click="logoI = []" class="rounded-full bg-red-600 w-4 h-4 flex items-center justify-center mx-2">
                <i style="font-size: 0.5rem;" class="pi pi-times text-white"></i>
              </button>
            </div>
          </div>
          <span class="text-red-700">{{ errLogo }}</span>
        </div>
      </div>
    </template>
    <template #footer>
      <div class="flex justify-end">
        <Button icon="pi pi-times" label="Cancel" class="p-button-secondary"
          @click="$router.push({name: 'pharmasan.dashboard.informe-ejecutivo'})"/>
        <Button icon="pi pi-check" label="Crear" class="ml-3" @click="onSubmit"/>
      </div>
    </template>

  </Card>
  <Dialog header="Agregar soportes" v-model:visible="displayMaximizable" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
    <div>
      <div class="my-2">
        <div class="mt-2">
          <FilePond ref="pond"/>
        </div>
      </div>
    </div>
    <template class="text-center" #footer>
      <Button label="Guardar" class="w-full" @click="closeMaximizable()" autofocus />
    </template>
  </Dialog>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useField, useForm } from 'vee-validate'
import { array, string, object } from 'yup'
import { quillEditor } from 'vue3-quill'
import storeInformesEjecucion from '../../store/informeEjecucion.store'
import Swal from 'sweetalert2'
import FilePond from './uploadImage.vue'
import FilePondStore from '@/apps/pharmasan/juridica/procesosJuridicos/store/filepond.store'
import PdfService from '../../services/verPdf.service'

export default {
  name: 'formCreate',
  components: {
    quillEditor,
    FilePond
  },
  setup () {
    /* computed */
    const adjunto = computed(
      () => FilePondStore.getters.adjunto
    )
    const _PdfService = new PdfService()
    const clientesList = computed(() => storeInformesEjecucion.getters.getClientes)
    /** instancias */
    const router = useRouter()
    const displayMaximizable = ref(false)
    const tiposInforme = ref(['Evento', 'Capita'])
    const aniosList = ref([])
    const pond = ref()
    const logoI = ref([])
    const adjuntos = ref([])
    const tipoGraficasFlujo = ref('Automatico')
    const title = ref('')
    const errFile = ref('')
    const errLogo = ref('')
    const mesesList = ref([
      { name: 'ENERO', code: '01' },
      { name: 'FEBRERO', code: '02' },
      { name: 'MARZO', code: '03' },
      { name: 'ABRIL', code: '04' },
      { name: 'MAYO', code: '05' },
      { name: 'JUNIO', code: '06' },
      { name: 'JULIO', code: '07' },
      { name: 'AGOSTO', code: '08' },
      { name: 'SEPTIEMBRE', code: '09' },
      { name: 'OCTUBRE', code: '10' },
      { name: 'NOVIEMBRE', code: '11' },
      { name: 'DICIEMBRE', code: '12' }
    ])
    const editorOptions = ref({
      placeholder: 'Diligencie el campo',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ header: 1 }, { header: 2 }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ direction: 'rtl' }],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],
          ['clean']
        ]
      }
    })
    // /* Esquema de validacion */
    const validationSchema = object({
      introduccion: string().required().label('Introducción'),
      tipo: string().required().label('Tipo de informe'),
      flujoRecursos: string().required().label('Flujo de Recursos'),
      cliente: string().required().label('Cliente'),
      mesInicial: string().required().label('Mes Inicial'),
      anio: string().required().label('Año'),
      mesFinal: string().required().label('Mes Final'),
      // gestionRequerimientos: string().required().label('Gestión de Requerimientos y PQRs'),
      conclusiones: string().required().label('Conclusiones'),
      moleculas: array().min(1).required().label('Moléculas')
    })
    const { errors, values: modelo, handleSubmit } = useForm({
      validationSchema
    })
    // handleReset
    useField('tipo', null, { initialValue: 'Evento' })
    // useField('tipoGraficasFlujo', null, { initialValue: 'Automatico' })
    useField('cliente', null, { initialValue: '' })
    useField('mesInicial', null, { initialValue: '' })
    useField('mesFinal', null, { initialValue: '' })
    useField('anio', null, { initialValue: '' })
    useField('introduccion', null, { initialValue: '' })
    useField('flujoRecursos', null, { initialValue: '' })
    useField('gestionSolicitudes', null, { initialValue: '' })
    useField('gestionRequerimientos', null, { initialValue: '' })
    useField('conclusiones', null, { initialValue: '' })
    useField('moleculas', null, { initialValue: [] })
    useField('solicitudesPlataforma', null, { initialValue: 0 })
    useField('requerimientosPqrs', null, { initialValue: 0 })
    useField('totalEntregas', null, { initialValue: 0 })
    useField('entregasDomicilio', null, { initialValue: 0 })
    useField('entregasOficinas', null, { initialValue: 0 })

    /* metodos */
    const getYears = () => {
      const years = []
      const yearNow = new Date().getFullYear()
      for (let index = 0; index < 5; index++) {
        years.push({ year: yearNow - index })
      }
      aniosList.value = years
    }
    const getListClientes = () => {
      storeInformesEjecucion.dispatch('storeGetClientesList')
    }
    const removeImage = (imagen) => {
      const index = adjuntos.value.findIndex(a => a.fileName === (imagen === 1 ? 'Tabla' : 'Grafica'))
      adjuntos.value.splice(index, 1)
    }
    const openMaximizable = (tipo) => {
      tipo === 1 ? title.value = 'Tabla' : (tipo === 2 ? title.value = 'Grafica' : title.value = 'Logo')
      displayMaximizable.value = true
    }
    const closeMaximizable = () => {
      if (title.value === 'Tabla' || title.value === 'Grafica') {
        pond.value.onSubmitSupport().then(() => {
          adjuntos.value.push({
            urlPath: adjunto.value.urlPath,
            fileName: title.value
          })
          displayMaximizable.value = false
          title.value = ''
        })
      }
      if (title.value === 'Logo') {
        pond.value.onSubmitSupport().then(() => {
          logoI.value.push({
            urlPath: adjunto.value.urlPath,
            fileName: title.value
          })
          displayMaximizable.value = false
          title.value = ''
        })
      }
    }
    const verPdf = (path) => {
      _PdfService.ver({ path })
    }
    const onSubmit = handleSubmit((values, actions) => {
      let errors = false
      if (tipoGraficasFlujo.value === 'Manual') {
        if (adjuntos.value.length <= 1) {
          errFile.value = 'Por favor ingrese los 2 recursos graficos'
          errors = true
        }
      }
      if (!logoI.value.length) {
        errLogo.value = 'Por favor ingrese un logo'
        errors = true
      }

      if (values.tipo === 'Evento' && values.gestionRequerimientos === '') {
        actions.setFieldError('gestionRequerimientos', 'Gestión de Requerimientos y PQRs es un campo requerido')
        errors = true
      }
      if (values.tipo === 'Capita') {
        if (values.gestionSolicitudes === '') {
          actions.setFieldError('gestionSolicitudes', 'Caracterización y Gestión de Solicitudes es un campo requerido')
          errors = true
        }
        if (['', null].includes(values.solicitudesPlataforma)) {
          actions.setFieldError('solicitudesPlataforma', 'Solicitudes en Plataforma es un campo requerido')
          errors = true
        }
        if (['', null].includes(values.requerimientosPqrs)) {
          actions.setFieldError('requerimientosPqrs', 'Requerimientos y PQRs es un campo requerido')
          errors = true
        }
        if (['', null].includes(values.totalEntregas)) {
          actions.setFieldError('totalEntregas', 'Total Entregas es un campo requerido')
          errors = true
        }
        if (['', null].includes(values.entregasDomicilio)) {
          actions.setFieldError('entregasDomicilio', 'Entregas a Domicilio es un campo requerido')
          errors = true
        }
        if (['', null].includes(values.entregasOficinas)) {
          actions.setFieldError('entregasOficinas', 'Entregas en Oficinas es un campo requerido')
          errors = true
        }
        if (adjuntos.value.length === 0) {
          errFile.value = 'Por favor ingrese el recurso grafico'
          errors = true
        }
      }
      values.logoI = logoI.value[0]
      if (!errors) {
        const {
          cliente,
          conclusiones,
          flujoRecursos,
          gestionRequerimientos,
          gestionSolicitudes,
          introduccion,
          mesInicial,
          mesFinal,
          anio,
          moleculas,
          tipo,
          solicitudesPlataforma,
          requerimientosPqrs,
          totalEntregas,
          entregasDomicilio,
          entregasOficinas,
          logoI
        } = values
        const clienteInfo = clientesList.value.find((c) => c.code === cliente)
        const payload = {
          cliente,
          conclusiones,
          flujoRecursos,
          gestionRequerimientos,
          gestionSolicitudes,
          introduccion,
          mesInicial,
          mesFinal,
          anio,
          moleculas,
          tipo,
          nombreCliente: clienteInfo.name,
          nombreLargoCliente: clienteInfo.longName,
          paths: adjuntos.value,
          solicitudesPlataforma,
          requerimientosPqrs,
          totalEntregas,
          entregasDomicilio,
          entregasOficinas,
          logoI
        }
        storeInformesEjecucion.dispatch('storeCreateInforme', payload).then(({ data }) => {
          Swal.fire({
            icon: 'success',
            title: 'Crear informe ejecutivo',
            text: 'Se creo el reporte exitosamente',
            showConfirmButton: true
          }).then(() => {
            router.push({ name: 'pharmasan.dashboard.informe-ejecutivo' })
          })
        })
      }
    })
    /* hooks */
    onMounted(() => {
      getYears()
      getListClientes()
    })
    return {
      tiposInforme,
      mesesList,
      errors,
      modelo,
      clientesList,
      editorOptions,
      aniosList,
      onSubmit,
      openMaximizable,
      closeMaximizable,
      displayMaximizable,
      adjuntos,
      adjunto,
      pond,
      errFile,
      removeImage,
      tipoGraficasFlujo,
      verPdf,
      errLogo,
      logoI
    }
  }
}
</script>

<style scoped>

</style>
