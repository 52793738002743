import { createStore } from 'vuex'
import dayjs from 'dayjs'
import { createInforme, getClientesList, getListInformes } from '../services/informeEjecutivo.service'
const storeInformesEjecucion = {
  state: {
    _listado: [],
    _total: 0,
    paginate: {
      rows: 10,
      first: 1,
      offset: 0
    },
    _clientes: []
  },
  getters: {
    getListado: state => state._listado,
    getPaginate: state => state.paginate,
    getClientes: state => state._clientes,
    getTotal: state => state._total
  },
  mutations: {
    setPaginate (state, payload) {
      state.paginate = payload
    },
    setListado (state, payload) {
      state._listado = payload
    },
    setClientes (state, payload) {
      state._clientes = payload
    },
    setTotal (state, payload) {
      state._total = payload
    }
  },
  actions: {
    getListadoInforme ({ commit, getters }, payload) {
      const paginate = getters.getPaginate
      const params = {
        filters: payload.filters,
        order: payload.order,
        limit: paginate.rows,
        offset: paginate.offset
      }
      getListInformes(params).then(({ data }) => {
        for (const x of data.rows) {
          x.createdAt = dayjs(x.createdAt).format('YYYY-MM-DD')
          x.mesInicial = dayjs(x.mesInicial).format('MMMM').toUpperCase()
          x.mesFinal = dayjs(x.mesFinal).format('MMMM').toUpperCase()
        }
        commit('setListado', data.rows)
        commit('setTotal', parseInt(data.total))
      })
    },
    storeCreateInforme (state, payload) {
      return createInforme(payload)
    },
    storeGetClientesList ({ commit }) {
      return getClientesList().then(({ data }) => {
        commit('setClientes', data)
      })
    }
  }
}

const store = createStore(storeInformesEjecucion)
export default {
  namespaced: true,
  ...store
}
